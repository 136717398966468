import React, {useRef, useEffect, useState  } from "react";
import { Container, Row, Col } from "reactstrap";

import { useParams } from "react-router-dom";
import destinationData from "../assets/data/destinationData.js";
import Helmet from "../components/Helmet/Helmet.js";
import { Link } from "react-router-dom";
import { RiArrowDropDownLine } from 'react-icons/ri'
// import Accordion from  "../components/UI/Accordion.jsx"
import "../styles/destination-details.css";
import "../styles/accordion.css";


const DestinationDetails = () => {
  const { slug } = useParams();
  const destination = destinationData.find((destination, index) => destination.name === slug);
  const [activeIndex, setActiveIndex] = useState(null);

  const contentHeight = useRef(null);

  useEffect(() => {
    
    if (contentHeight.current) {
    }
  }, [activeIndex, contentHeight]);


  const handleItemClick = (index, e) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    
    e.preventDefault(); 
    
  };

  const getHeight = (index, e) => {
    if (activeIndex === index) {
      
      return 'auto';
      
    } else {
      return '0px'; 
    }
  };



  // {tour.title === 'Cancellation Policy' && (
  //               <a href="/cancellation" className="cancelation-button">Read More about cancelation Policy here</a>
  //             )}


  
  return (
    <Helmet title={destination.name}>
      <section>
        <Container>
          <Row>
            <Col lg="8" md="8">
              <div className="blog__details">
                <img src={destination.imgUrl} alt="" className="w-100" />
{/* {destination.name === 'Cape Peninsula' && (
  <h2 className="section__title mt-4">
    Cape of Good Hope and Penguins Tour in Cape Town
  </h2>
)}
{destination.name !== 'Cape Peninsula' && (
  <h2 className="section__title mt-4">
    {destination.name} Tour
  </h2>
)} */}
<h2 className="section__title mt-4">
  {destination.name === 'Cape Peninsula' ? (
    <>{destination.name} Cape of Good Hope and Penguins Tour in Cape Town</>
  ) : (
    <>{destination.name}</>
  )}
</h2>


                <div className="blog__publisher d-flex align-items-center gap-4 mb-4">
                  
                   <span className="destination__price">
                   {destination.price}
                  </span>
                
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i className="ri-calendar-line"></i> Reserve Now & Pay Later
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i className="ri-message-line"></i> Offered in:English
                  </span>
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i className="ri-car-line"></i> Pickup offered
                  </span>
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i className="ri-checkbox-circle-line"></i> Free Cancelation
                  </span>
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i className="ri-time-line"></i>    {destination.duration}
                  </span>
                </div>
                <h2>Overview</h2>
                <p className="section__description">{destination.description}</p>
              </div>

              <div className='accordion-container'>
           {destination.tourDetails.map((tour, index) => (
         <div className="wrapper" key={index}>
          <button className={`question-container ${activeIndex === index ? 'active' : ''}`} onClick={(e) => handleItemClick(index, e)}>
            <p className='question-content'>{tour.title}</p>
            <RiArrowDropDownLine className={`arrow ${activeIndex === index ? 'active' : ''}`} />
          </button>

          <div className="answer-container" style={{
            height: getHeight(index),
            overflow: 'hidden',
            transition: 'height 0.3s ease'
          }}>
          <div className="answer-content">
  <ul>
    {Array.isArray(tour.details) ? (
      tour.details.map((detail, index) => (
        <li key={index}>{`- ${detail}`}</li>
      ))
    ) : typeof tour.details === 'object' ? (
      Object.entries(tour.details).map(([key, value]) => (
       <li key={key} className="details-list">
          <strong>{key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()).trim()}:</strong> {value}
        </li>
      ))
    ) : null}
  </ul>
   {tour.title === 'Cancellation Policy' && (
                <a href="/cancellation" className="cancelation-button">Read More about cancelation Policy here</a>
              )}
</div>
          </div>
        </div>
      ))}
    </div>

<div className="itinerary-content">
  <div>
    <h2>Itinerary</h2>
    {destination.itinerary.map((location, index) => (
      <div key={index}>
        <div>
          <h5>{location.title}</h5>
          <h3>{`Stop : ${location.details.stop}`}</h3>
          <img  className="location-img" src={location.details.locationImage} alt="" />
          <p className="section__description">{location.details.description}</p>
          <h4>{`End : ${location.details.end}`}</h4>
        </div>
        <div>
        
        <iframe
        title="map"
  width="520"
  height="400"
  frameborder="0"
  scrolling="no"
  marginheight="0"
  marginwidth="0"
  id="gmap_canvas"
  src={`https://maps.google.com/maps?width=520&height=400&hl=en&q=${encodeURIComponent(location.title)}&t=&z=12&ie=UTF8&iwloc=B&output=embed`}
></iframe>
 <script type='text/javascript' src='https://embedmaps.com/google-maps-authorization/script.js?id=c2014cc8cb624917ee91025f6677f5a21a86bd2e'></script>
        </div>
      </div>
    ))}
  </div>
</div>

              <div className="d-flex align-items-center gap-3">
              
            <Link to={`/booking/${destination.name}`} className="book__now">
            Book Now
          </Link>

            </div>
          
            </Col>
      
            <Col lg="4" md="4">
              <div className="recent__post mb-4">
                <h5 className=" fw-bold">Top Destinations</h5>
              </div>
              {destinationData.map((item) => (
                <div className="recent__blog-post mb-4" key={item.id}>
                  <div className="recent__blog-item d-flex gap-3">
                    <img src={item.imgUrl} alt="" className="w-25 rounded-2" />
                    <h6>
                      <Link to={`/destination/${item.name}`}>{item.name}</Link>
                    </h6>
                  </div>
                </div>
              ))}
            </Col>
          </Row>
          
        </Container>
      </section>
    </Helmet>
  );
};

export default DestinationDetails;
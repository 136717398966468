import React, { useEffect } from "react";

import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet.js";
import { useParams } from "react-router-dom";
import BookingForm from "../components/UI/BookingForm.jsx";
import destinationsData from "../assets/data/destinationData.js";

const BookingDetails = () => {
  const { slug } = useParams();

  // const singleCarItem = carData.find((item) => item.carName === slug);

  const destination = destinationsData.find((destination) => destination.name === slug);

  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [destination]);

  return (
    <Helmet title={destination.name}>
      <section>
        <Container>
          <Row>
            <Col lg="6">
              <img src={destination.imgUrl} alt="" className="w-100" />
            </Col>

            <Col lg="6">
              <div className="car__info">
                <h2 className="section__title">{destination.name}</h2>

                <div className=" d-flex align-items-center gap-5 mb-4 mt-3">
                  <h6 className="rent__price fw-bold fs-4">
                    {destination.price}
                  </h6>

                  <span className=" d-flex align-items-center gap-2">
                    <span style={{ color: "#f9a826" }}>
                      <i class="ri-star-s-fill"></i>
                      <i class="ri-star-s-fill"></i>
                      <i class="ri-star-s-fill"></i>
                      <i class="ri-star-s-fill"></i>
                      <i class="ri-star-s-fill"></i>
                    </span>
                   ratings
                  </span>
                </div>

                <p className="section__description">
                  {destination.description}
                </p>

                <div
                  className=" d-flex align-items-center mt-3"
                  style={{ columnGap: "4rem" }}
                >
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-roadster-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                    Modern Cars
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-settings-2-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                  24/7 Support
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-timer-flash-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                   2 Hours from CBD
                  </span>
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i class="ri-user-line" style={{ color: "#f9a826" }}></i>{" "}
                   Friendly Drivers
                  </span>
                </div>

                <div
                  className=" d-flex align-items-center mt-3"
                  style={{ columnGap: "2.8rem" }}
                >
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i class="ri-map-pin-line" style={{ color: "#f9a826" }}></i>{" "}
                   live gps 
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-wheelchair-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                    comfortable cars
                  </span>
                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-checkbox-circle-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                   Free Cancelation
                  </span>

                  <span className=" d-flex align-items-center gap-1 section__description">
                    <i
                      class="ri-building-2-line"
                      style={{ color: "#f9a826" }}
                    ></i>{" "}
                    100% guaranteed refunds
                  </span>
                </div>
              </div>
            </Col>

            <Col lg="7" className="mt-5">
              <div className="booking-info mt-5">
                <h5 className="mb-4 fw-bold ">Booking Information</h5>
                <BookingForm name={destination.name} price={destination.price}/>
              </div>
        
            </Col>

            {/* <Col lg="5" className="mt-5">
              <div className="payment__info mt-5">
                <h5 className="mb-4 fw-bold ">Payment Information</h5>
                <PaymentMethod />
              </div>
            </Col> */}

            
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default BookingDetails;

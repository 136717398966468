// import images from all-images/blog-img directory
// import img01 from "../all-images/blog-img/tour-01.jpg";
import img02 from "../all-images/blog-img/tour-02.jpg";
// import img03 from "../all-images/blog-img/tour-03.jpg";
import img04 from "../all-images/blog-img/tour-04.jpg";
import img05 from "../all-images/blog-img/tour-05.jpg";
// import img06 from "../all-images/blog-img/tour-06.jpg";
// import img07 from "../all-images/blog-img/tour-7.jpg";
// import img08 from "../all-images/blog-img/tour-08.jpg";
// import img09 from "../all-images/blog-img/tour-09.jpg";
// import img10 from "../all-images/blog-img/tour-10.jpg";
// import img11 from "../all-images/blog-img/tour-11.jpg";
import img12 from "../all-images/blog-img/tour-12.jpg";

const destinationsData = [
  {
    id: 1,
    name: "Roben Island",
    duration: "4 hours",
    price: "R1450 / Person",
    imgUrl: img02,
    description: "This half-day package includes museum admission and round-trip transfer from your Cape Town accommodation, making your trip hassle-free. Robben Island is one of the most visited museums in South Africa and is best booked well in advance. Take a moment to reflect on your voyage back to the mainland after learning about the island's long history and seeing Nelson Mandela's cell.",
    tourDetails : [
      {
        title: "Whats Included",
        details: [
          "Air-conditioned vehicle",
          "WiFi on board",
          "Bottled water",
          "Bottled water onboard the vehicle",
          "Entry/Admission - Robben Island Museum"
        ]
      },
      {
        title: "Whats Not Included",
        details: [
          "Food and drinks",
          "Gratuities"
        ]
      },
      {
              title: "What To Expect",
              details: {
                stopAt: "Robben Island Museum, Cape Town Central 7400 South Africa",
                pickUp: "Pick up at accommodation in Cape Town. You will receive a shared transfer from your hotel in Cape Town to the Silo District where you will be escorted to the Nelson Mandela Gateway.",
                charter: "Here you will charter a shared boat to Robben Island museum where you will be met by a former political prisoner who will conduct the tour of the Island. (Please note that our tour guide/driver will not be joining you on the tour of the Robben Island museum.)",
                tourDuration: "The entire tour lasts 4 hours which includes hotel pick up.",
                islandTransport: "For part of the tour, you will be transported around the island by bus, before you visit the prison cell of Nelson Mandela and take the long walk to freedom.",
                arrivalBack: "Upon our arrival back at the Jetty, your Tour Guide/Driver will meet you at the entrance of the Nelson Mandela Gateway.",
                totalDuration: "Duration: 5 hours"
              }
            },
            {
              title: "Departure and Return",
              details: {
                pickupDetails: "Pickup is available from all hotels in Sea Point and City Centre. All hotels in City Centre and Sea Point can provide return transport. The driver will not board the Robben Island boat; instead, they will transfer to Nelson Mandela Gateway. If you are prone to seasickness, please take seasick medications before boarding, pack a jacket, wear comfortable shoes, and carry food. Please have a copy of your passport or a phone. Note that this pertains to return transfers to and from the hotel.",
                hotelPickup: " Pick up at accommodation in Cape Town. You will receive a shared transfer from your hotel in Cape Town to the Silo District where you will be escorted to the Nelson Mandela Gateway. Here you will charter a shared to Robben Island museum where you will be met by a former political prisoner who will conduct the tour of the Island. (Please note that our tour guide/driver will not be joining you on the tour of the Robben Island museum.) The entire tour lasts +-4 hours which includes hotel pick up. For part of the tour, you will be transported around the island by bus, before you visit the prison cell of Nelson Mandela and take the long walk to freedom. Upon our arrival back at the Jetty, your tour guide or driver will meet you at the entrance of the Nelson Mandela Gateway. . Visit the man-made facilities . Get to see where isolate South African leaders, including Jacob Zuma, Kgalema Motlanthe, and Nelson Mandela . Explore African penguin.",
                portPickup: "During checkout you will be able to select from the list of included ports.",
                end: "This activity ends back at the meeting point."
              }
            },
            {
              title: "Accessibility Information",
              details: {
                  wheelchair: "wheelchair accessible",
                  animals: "Service animals allowed",
                  publicTransportation: "Near public transportation",
                  infants: "Infants must sit on laps",
                  health: "Travelers should have a moderate physical fitness level",
              }
            },
            {
              title: "Additional Information",
              details: [
                "Confirmation will be received at the time of booking",
                "No heart problems or other serious medical conditions",
                "Book in advance to avoid disappointment on the day",
                "Round-trip transfer from your hotel ensures a hassle-free trip",
                "This tour/activity will have a maximum of 32 travellers",
                "Benefit from the historical insights of your small-group guide",
                "See the cell that confined Nelson Mandela for 18 years"
              ]
            },
            {
              title: "Cancellation Policy",
              details:[ "For a full refund, cancel at least 24 hours in advance of the start date of the experience.",
                    "If you cancel less than 24 hours before the experience’s start time, the amount you paid will not be refunded",
                    "Any changes made less than 24 hours before the experience’s start time will not be accepted",
                    "Cut-off times are based on the experience’s local time",
                    "This experience requires good weather, If it’s canceled due to poor weather, you’ll be offered a different date or a full refund",
                    "This experience requires a minimum number of travelers, If it’s canceled because the minimum isn’t met, you’ll be offered a different date/experience or a full refund.",
                     
            ]
            },
            {
              title: "Help",
              details: {
                note: "If you have questions about this tour or need help making your booking, we’d be happy to help. phoneNumber: +44 12 2492 8241, product name: 240572P5"
              }
            },

    ],
    itinerary :[
      {
      title: "Robben Island Museum",
      details: {
        pickedup: "You will get picked up at your Hotel",
        stop:"5 hours",
        locationImage:"https://media-cdn.tripadvisor.com/media/photo-o/17/a4/f2/14/robben-islandhas-been.jpg",
        description:"Pick up at accommodation in Cape Town. You will receive a shared transfer from your hotel in Cape Town to the Silo District where you will be escorted to the Nelson Mandela Gateway. Here you will charter a shared to Robben Island museum where you will be met by a former political prisoner who will conduct the tour of the Island. (Please note that our tour guide/driver will not be joining you on the tour of the Robben Island museum.) The entire tour lasts +-4 hours which includes hotel pick up. For part of the tour, you will be transported around the island by bus, before you visit the prison cell of Nelson Mandela and take the long walk to freedom. Upon our arrival back at the Jetty, your Tour Guide/Driver will meet you at the entrance of the Nelson Mandela Gateway.",
       end:"You'll return to the starting point",

      }
    }]
      
  },

  {
    id: 2,
    name: "Cape Peninsula ( Cape of Good Hope and Penguins Tour in Cape Town )",
    duration: "9 hours",
    price: "From ZAR 2,565.66 / Person",
    imgUrl: img04,
    description:"Discover the legendary Cape of Good Hope, where the land meets the sea at the southwestern tip of Africa, and the Atlantic and Indian Oceans collide in a breathtaking dance of waves. Embark on a scenic voyage along the Cape Peninsula, where every twist and turn reveals a new postcard-perfect view, with nature's masterpiece awaiting at every corner.         Escape to the wild at Aquila Game Reserve, where you can encounter the Big 5 and more in their natural habitat, beginning a safari adventure as you tread lightly on the footsteps of giants.",
    tourDetails : [
            {
        title: "What makes this tour unique?",
        details: [
       "Taking you to Cape Town, Cape of Good Hope, and Penguins Drive, as well as hiking around the Cape Peninsula, this full-day sightseeing tour emphasizes the region's natural attractions. You will see penguins and other wildlife at Boulders Beach, Cape Fur, and Cape Point Nature Reserve, as well as take a scenic tour of the Cape Peninsula. You can see the Twelve Apostles from Camps Bay, visit Seal Island, drive Chapman's Peak's 114 curves, marvel at the 7,000 plants at Kirstenbosch National Botanical Garden, and more in Clifton.",
"A full-day sightseeing tour of Cape Peninsula",
"An ideal overview of the area for first-time visitors",
"See the highlights of the region on one tour",
"A private tour exclusively for your group",
"A mix of hiking and driving for different sightseeing options",
"Focus on natural attractions, including a penguin colony (optional)",
"Guided commentary provides deeper insight into attractions",
"Light snacks and picnic lunches included"
        ]
      },
      {
        title: "Whats Included",
        details: [
          "Pickup and drop-off at selected accommodations and hotels",
          "Light morning snack",
          "Qualified guide",
          "WiFi on board",
          "Hikinge"
        ]
      },
      {
        title: "Whats Not Included",
        details: [
       "Cape Point Nature entry ticket: R400 foreigner, R200 foreign child, R94",
       "Optional Boat Cruise to the seal colony from Hout Bay Harbour (R140",
       "Optional Boulders Beach entry: R190 foreigner, R95 foreign child, R25 local",
       "Cape Of Good Hope entry ticket: R400 foreigner, R200 foreign child, R94",
        ]
      },
      {
              title: "What To Expect",
              details: [
                "Get picked up from your Cape Town hotel and travel by air-conditioned minivan to Bo-Kaap, for an informative walk around the colorful neighborhood, before a quick photo stop at Camps Bay.",
                "At Hout Bay, explore the scenic town, market, or beach, or take an optional ferry (own expense) to Duiker Island to see cape fur seals. Stop for photos at Chapman’s Peak Drive and Cape Point Ostrich Farm on the way to the Cape Point Nature Reserve, which you can explore on foot or vehicle. Choose from different trails to hike at Cape of Good Hop. ",
                "Stop at the Old Cape Point Lighthouse before lunch (own expense) at Simon’s Town. Opt to include entrance tickets with your tour, and your guide will provide tickets for you to Boulders Bay Penguin Colony. Enjoy time on the beach with the penguins with helpful narrative you’re your guide before returning to Cape Town."
            ]
            },
            {
              title: "Departure and Return",
              details: {
                start: "Multiple pickup locations offered.",
                pickupDetails: "When booking, look at the pickup list to see if your hotel is on it. If not, select 'I will decide later' or 'My hotel is not on the list' and let us know where you will be staying. You will be informed whether we can pick you up directly from your hotel or a nearby alternative.",
                hotelPickup: "During the booking you will be able to select from the list of included hotels.",
                end: "This activity ends back at the meeting point."
              }
            },
            
           {
              title: "Guides and languages",
              details: {
                  InPerson: "English, French",
                  Audio: ": None",
                  Written: " None",
                  GuideLicence: "In-person guide is licensed or certified",
                  GuideInfo: "In-person guide is also the driver",
              }
            },

            {
              title: "Accessibility Information",
              details: {
                  wheelchair: "Not wheelchair accessible",
                  infants: "An infant seat will be available upon request.",
                  infantSeats: "Infant seats available",
              }
            },
            {
              title: "Additional Information and Health Restrictions",
              details: [
                "Confirmation will be received at the time of booking",
                "No heart problems or other serious medical conditions",
                "Not recommended for pregnant travelers",
                "Most travellers can participate",
                "Not recommended for travelers with back problems",
                "This tour/activity will have a maximum of 13 travellers"
              ]
            },
            {
              title: "Cancellation Policy",
              details:[ "For a full refund, cancel at least 24 hours in advance of the start date of the experience."
            ]
            },
            {
              title: "Help",
              details: {
                note: "If you have questions about this tour or need help making your booking, we’d be happy to help. phoneNumber: +44 12 2492 8241, product name: 240572P5"
              }
            },
          
    ],
    itinerary :[
      {
      title: "Cape of Good Hope",
      details: {
        pickedup: "You will get picked up at your Hotel",
        stop:"9 hours",
        locationImage:"https://lh3.googleusercontent.com/p/AF1QipOeqQVs2IrPmce5KREr12YvcI2jdqUw4ydynxCr=s1360-w1360-h1020",
        description:"Pick up at accommodation in Cape Town. You will receive a shared transfer from your hotel in Cape Town to the Silo District where you will be escorted to the Nelson Mandela Gateway. Here you will charter a shared to Robben Island museum where you will be met by a former political prisoner who will conduct the tour of the Island. (Please note that our tour guide/driver will not be joining you on the tour of the Robben Island museum.) The entire tour lasts +-4 hours which includes hotel pick up. For part of the tour, you will be transported around the island by bus, before you visit the prison cell of Nelson Mandela and take the long walk to freedom. Upon our arrival back at the Jetty, your Tour Guide/Driver will meet you at the entrance of the Nelson Mandela Gateway.",
       end:"You'll return to the starting point",
      }
    }]
  },
    
  {
    id: 3,
    name: "Aquila Game Reserve",
    duration: "2h 30m",
    price: "R4250 / Person",
    imgUrl: img05,
    description:"Immerse yourself in the wild wonders of Africa at the Aquila Game Reserve, a sanctuary for the Big 5 and a haven for nature enthusiasts. Just a scenic drive from Cape Town, Aquila offers an authentic safari experience with game drives through the pristine landscapes, revealing lions, elephants, rhinos, leopards, and buffalos. Relax in luxury lodges, savoring the serenity of the bushveld, and embark on a journey that transcends the ordinary. Aquila Game Reserve is your gateway to the heart of the African wilderness, where every moment is a glimpse into the untamed beauty of the animal kingdom. At Aquila, the rhythm of the savannah becomes your own as you encounter majestic wildlife and witness the magic of the African bush. It's not just a safari; it's a profound connection with nature that leaves you in awe of the untamed splendor that defines the spirit of the continent.",
    tourDetails : [
            {
        title: "Whats Included",
        details: [
          "Breakfast",
          "Lunch",
          "Air-conditioned vehicle",
          "Safari",
        ]
      },
      {
        title: "Whats Not Included",
        details: [
          "Only welcome drink is included in the price. Only adults over the age of 18 are allowed to purchase alcoholic beverages",
        ]
      },
      {
              title: "What To Expect",
             details: [
          "Embark on the Best of Aquila Safari Full-day Tour from Cape Town. Depart between 08:00 and 09:00, returning at 18:00. Highlights include traversing the wine region, Klein Karoo, and Ceres' fruit-growing region. Enjoy welcome drinks upon arrival, followed by a thrilling two-hour game drive in an open-air 4x4. Visit the lion preservation project with breakfast. Indulge in an eco-synergy lunch under the shade of trees or in a boma-style restaurant. Unwind by the pool in summer or beside a cozy fireplace in winter. Return to Cape Town through the Huguenot tunnel, with the opportunity to purchase wine. Wi-Fi available on board.",
        ]
            },
            {
              title: "Departure and Return",
              details: {
                pickupDetails: "Our transport team will confirm pick-up time the day before your arrival date. Please provide us with a contact number for Whatapp or a phone call",
                hotelPickupOffered: "During the booking you will be able to select from the list of included hotels.",
                end: "This activity ends back at the meeting point."
              }
            },
            {
              title: "Accessibility Information",
              details: {
                  wheelchair: "Not wheelchair accessible",
                  stroller: "Stroller accessible",
                  publicTransportation: "Near public transportation",
                  infants: "Infants must sit on laps",
                  infantSeats: "Infant seats available",
              }
            },
            {
              title: "Additional Information",
              details: [
                "Confirmation will be received at the time of booking",
                "No heart problems or other serious medical conditions",
                "Most travellers can participate",
                "This experience requires good weather. If it’s cancelled due to poor weather, you’ll be offered a different date or a full refund",
                "This tour/activity will have a maximum of 100 travellers"
              ]
            },
            {
              title: "Cancellation Policy",
              details:[ "For a full refund, cancel at least 24 hours in advance of the start date of the experience."
            ]
            },
            {
              title: "Help",
              details: {
                note: "If you have questions about this tour or need help making your booking, we’d be happy to help. phoneNumber: +44 12 2492 8241, product name: 240572P5"
              }
            },
          
    ],
    itinerary :[
      {
      title: "Aquila Game Reserve",
      details: {
        pickedup: "You will get picked up at your Hotel",
        stop:"5 hours",
        locationImage:"https://lh3.googleusercontent.com/p/AF1QipNhvfEAz_arT9hNfs_KI8_Fa6YhlOgUx1V6Sk35=s1360-w1360-h1020",
        description:"Pick up at accommodation in Cape Town. You will receive a shared transfer from your hotel in Cape Town to the Silo District where you will be escorted to the Nelson Mandela Gateway. Here you will charter a shared to Robben Island museum where you will be met by a former political prisoner who will conduct the tour of the Island. (Please note that our tour guide/driver will not be joining you on the tour of the Robben Island museum.) The entire tour lasts +-4 hours which includes hotel pick up. For part of the tour, you will be transported around the island by bus, before you visit the prison cell of Nelson Mandela and take the long walk to freedom. Upon our arrival back at the Jetty, your Tour Guide/Driver will meet you at the entrance of the Nelson Mandela Gateway.",
       end:"You'll return to the starting point",
      }
    }]
  },
  {
    id: 4,
    name: "Private Winelands and Stellenbosch Franschhoek Wine Tasting tour",
    duration: "7h",
    price: "From ZAR 1,999.99 / Person",
    imgUrl: img12,
    description: "Enjoy some of the famous wines of the Cape Winelands as well as historic towns and scenic drives while exploring Stellenbosch, Paarl, and Franschhoek. We visit three wineries in Stellenbosch ( Paarl or Franschhoek), with tastings included, history, and culture of the area. The valleys of Stellenbosch have been growing grapes since the 1600s, making them the largest wine-producing region in the nation. In the fall, Franschhoek, Paarl, Pniel, Stellenbosch, Klapmuts, Wellington, and Tulbagh grapevines turn a vibrant crimson, ochre, and brown.   Wine tour from Cape Town Visit the spectacular Paarl Valley where you can enjoy a wine-tasting and cellar tour See the beautiful village of Franschhoek Walk around Stellenbosh, the second oldest town in South Africa! Enjoy this relaxing day out in the wine lover's paradise",
    tourDetails : [
      {
        title: "Whats Included",
        details: [
          "Hotel pick-up and drop-off from central Cape Town hotels",
          "Qualified guide",
          "3 wine tastings",
          "WiFi on board",
          "Cellar tour",
        ]
      },
      {
        title: "Whats Not Included",
        details: [
          "Food and drinks, unless specified",
          "Gratuities",
        ]
      },
      {
              title: "What To Expect",
              details: ["Get picked up at your central Cape Town hotel and begin your day trip into Western Cape wine country. Visit the town of Paarl in the Paarl Valley, where you’ll visit a wine estate for a cellar tour and wine-tasting session, along with winery stops in pretty Franschhoek and Stellenbosch. It’s second only to Cape Town as the oldest European settlement in the province, and where the region’s winemaking got its start. The Pinotage grape is unique to South Africa, having been hybridized in the country, but it’s not the only wine you’ll taste. Other varietals are grown in South Africa, too, including Shiraz, Semillon, and Chardonnay. During your stops in Paarl and Stellenbosch, enjoy some free time to explore the towns on foot at your leisure. After the last tasting of this relaxing day, head back to Cape Town aboard the vehicle. Please note that the order of stops may differ depending on the day"]
               
            },
            {
              title: "Departure and Return",
              details: {
                pickupDetails: "When booking, look at the pickup list to see if your hotel is on it. If not, select 'I will decide later' or 'My hotel is not on the list' and let us know where you will be staying. You will be informed whether we can pick you up directly from your hotel or a nearby alternative.",
                CapeTown: "Get picked up at your central Cape Town hotel and begin your day trip into Western Cape wine country. Visit the town of Paarl in the Paarl Valley, where you’ll visit a wine estate for a cellar tour and wine-tasting session, along with winery stops in pretty Franschhoek and Stellenbosch. It’s second only to Cape Town as the oldest European settlement in the province, and where the region’s winemaking got its start. The Pinotage grape is unique to South Africa, having been hybridized in the country, but it’s not the only wine you’ll taste. Other varietals are grown in South Africa, too, including Shiraz, Semillon, and Chardonnay. During your stops in Paarl and Stellenbosch, enjoy some free time to explore the towns on foot at your leisure. After the last tasting of this relaxing day, head back to Cape Town aboard the vehicle. Please note that the order of stops may differ depending on the day.",
                Stellenbosch : "Enjoy a wine-tasting Stellenbosch. Discover the vineyards of Stellenbosch on our guided wine tasting day tour. Get the perfect introduction to South Africa’s vibrant wine tasting experience at awards winning wineries on this full-day tour from Cape Town.",
                Paarl : "Enjoy a Wine tasting in Paarl. Get the perfect introduction to South Africa's vibrant wine tasting experience. Get the perfect introduction to South Africa's vibrant wine tasting experience at award-winning wineries on this full-day tour from Cape Town. ",
                Franschhoek : "Enjoy wine tasting in Franschhoek - Enjoy a full-day tour of award-winning wineries in Franschhoek, South Africa. Get the perfect introduction to South Africa's vibrant wine tasting experience.",
                end: "This activity ends back at the meeting point."
              }
            },
            {
              title: "Accessibility Information",
              details: {
                  wheelchair: "Not wheelchair accessible",
                  stroller: "Service animals allowed ",
                  publicTransportation: "Near public transportation",
                  infants: "An infant seat will be available upon request",
              }
            },
            {
              title: "Additional Information",
              details: [
                "Confirmation will be received at the time of booking",
                "No heart problems or other serious medical conditions",
                "Not recommended for travelers with back problems",
                "Not recommended for pregnant travelers",
                "Most travellers can participate",
                "This tour/activity will have a maximum of 13 travelers",
              ]
            },
            {
              title: "Cancellation Policy",
              details:[ 
                "You can cancel up to 24 hours in advance of the experience for a full refund.",
                 "For a full refund, you must cancel at least 24 hours before the experience’s start time.",
                 "If you cancel less than 24 hours before the experience’s start time, the amount you paid will not be refunded.",
                 "Any changes made less than 24 hours before the experience’s start time will not be accepted",
                 "Cut-off times are based on the experience’s local time.",
                 "This experience requires good weather. If it’s canceled due to poor weather, you’ll be offered a different date or a full refund",
                 "This experience requires a minimum number of travelers. If it’s canceled because the minimum isn’t met, you’ll be offered a different date/experience or a full refund",
            ]
            },
            {
              title: "Help",
              details: {
                note: "If you have questions about this tour or need help making your booking, we’d be happy to help. phoneNumber: +44 12 2492 8241, product name: 240572P5"
              }
            },
          
    ],
    itinerary :[
      {
      title: "The House of JC Le Roux",
      details: {
        pickedup: "Cape Town Get picked up at your central Cape Town hotel and begin your day trip into Western Cape wine country. Visit the town of Paarl in the Paarl Valley, where you’ll visit a wine estate for a cellar tour and wine-tasting session, along with winery stops in pretty Franschhoek and Stellenbosch. It’s second only to Cape Town as the oldest European settlement in the province, and where the region’s winemaking got its start. The Pinotage grape is unique to South Africa, having been hybridized in the country, but it’s not the only wine you’ll taste. Other varietals are grown in South Africa, too, including Shiraz, Semillon, and Chardonnay. During your stops in Paarl and Stellenbosch, enjoy some free time to explore the towns on foot at your leisure. After the last tasting of this relaxing day, head back to Cape Town aboard the vehicle. Please note that the order of stops may differ depending on the day. Stellenbosch Enjoy a wine-tasting Stellenbosch. Discover the vineyards of Stellenbosch on our guided wine tasting day tour. Get the perfect introduction to South Africa’s vibrant wine tasting experience at awards winning wineries on this full-day tour from Cape Town. Paarl Enjoy a Wine tasting in Paarl. Get the perfect introduction to South Africa's vibrant wine tasting experience. Get the perfect introduction to South Africa's vibrant wine tasting experience at award-winning wineries on this full-day tour from Cape Town. Franschhoek Enjoy wine tasting in Franschhoek - Enjoy a full-day tour of award-winning wineries in Franschhoek, South Africa. Get the perfect introduction to South Africa's vibrant wine tasting experience.",
        stop:"5 hours",
        locationImage:"https://dam.distell.co.za/m/463f587149d25b6d/original/JC-Le-Roux-deck-front.jpg",
        description:"Pick up at accommodation in Cape Town. You will receive a shared transfer from your hotel in Cape Town to the Silo District where you will be escorted to the Nelson Mandela Gateway. Here you will charter a shared to Robben Island museum where you will be met by a former political prisoner who will conduct the tour of the Island. (Please note that our tour guide/driver will not be joining you on the tour of the Robben Island museum.) The entire tour lasts +-4 hours which includes hotel pick up. For part of the tour, you will be transported around the island by bus, before you visit the prison cell of Nelson Mandela and take the long walk to freedom. Upon our arrival back at the Jetty, your Tour Guide/Driver will meet you at the entrance of the Nelson Mandela Gateway.",
       end:"You'll return to the starting point",
      }
    }]
  },
];

export default destinationsData;

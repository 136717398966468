import React from "react";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "../../styles/footer.css";
import logo from "../../assets/all-images/logo.jpg";

const quickLinks = [
  {
    path: "/about",
    display: "About",
  },

  {
    path: "/services",
    display: "Services",
  },

  {
    path: "/destinations",
    display: "Destinations",
  },
  // {
  //   path: "/combined-tours",
  //   display: " Combined Tours",
  // },

  {
    path: "/contact",
    display: "Contact",
  },
];

const legalQuickLinks = [
  // {
  //   path: "/about",
  //   display: "Pickup Details",
  // },

  {
    path: "/privacy",
    display: "Privacy Policy",
  },

  {
    path: "/cancelation",
    display: "Cancellation Policy",
  },

];
const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="4" md="4" sm="12">
            <div className="logo footer__logo">
              <h1>
                <Link to="/home" className=" d-flex align-items-center gap-2">
                <img src={logo} alt=""/>
                  <span>
                    HYM Cape Tours 
                  </span>
                </Link>
              </h1>
    
            </div>
            <p className="footer__logo-content">
            With HYM Cape Tours, every trip is a seamless blend of luxury, adventure, and cultural discovery. Let us be your guide to the extraordinary, as we redefine the way you experience the wonders of Cape Town and beyond. Your adventure begins here!"
            </p>
          </Col>

          <Col lg="2" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">Quick Links</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>

          <Col lg="3" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">Head Office</h5>
              <p className="office__info">2A Wemyss St, Brooklyn, Cape Town, 7405, South Africa</p>
              <p className="office__info">Phone: +27 79 968 3317</p>

              <p className="office__info">Email: hymcapetours@gmail.com</p>

              <p className="office__info">Office Time: 24/7</p>
            </div>
          </Col>

          <Col lg="3" md="4" sm="12">
            <div className="mb-4">
              {/* <h5 className="footer__link-title">Newsletter</h5>
              <p className="section__description">Subscribe our newsletter</p>
              <div className="newsletter">
                <input type="email" placeholder="Email" />
                <span>
                  <i class="ri-send-plane-line"></i>
                </span>
              </div> */}
                <h5 className="footer__link-title">Quick Links</h5>
              <ListGroup>
                {legalQuickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
              <p className="section__description">Follow us on Social Media</p>
              <div className="social">
                <span>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/hym_cape_tours/"><i class="ri-instagram-line"></i></a>
                  <i class="ri-facebook-line"></i>
                  <i class="ri-youtube-line"></i>
                </span>
              </div>
            </div>
          </Col>

          <Col lg="12">
            <div className="footer__bottom">
              <p className="section__description d-flex align-items-center justify-content-center gap-1 pt-4">
                <i class="ri-copyright-line"></i>Copyright {year}, Made with love by
                <a href="https://www.linkedin.com/in/zach%C3%A9e-niyokwizera-937a791a2/">Zachee.</a> All rights reserved.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;

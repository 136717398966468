import React, { useState } from 'react';
import "../../styles/booking-form.css";
import emailjs from 'emailjs-com';
import { Form, FormGroup } from "reactstrap";

const BookingForm = ({ name, price }) => {
  const [statusMessage, setStatusMessage] = useState('');
  const bookingEmailHandler = (e) => {
    e.preventDefault();
    // console.log(e.target.elements.first_name);
    // console.log(e.target.elements.first_name.value);
    // console.log('Email sent successfully:')
    const formData = {
      first_name: e.target.elements.first_name.value,
      last_name: e.target.elements.last_name.value,
      email: e.target.elements.email.value,
      phone_number: e.target.elements.phone_number.value,
      destination: e.target.elements.destination.value,
      price: e.target.elements.price.value,
      number_of_people: e.target.elements.number_of_people.value,
      pick_up_address: e.target.elements.pick_up_address.value,
      date: e.target.elements.date.value,
      depart_time: e.target.elements.depart_time.value,
      message: e.target.elements.message.value,
    };

    emailjs.send(
      'service_lsg9iqw',
      'template_vouoox7',
      {
        to_name: 'Yves',
        first_name: formData.first_name,
        last_name: formData.last_name,
        phone_number: formData.phone_number,
        user_email: formData.email,
        destination: formData.destination,
        price: formData.price,
        number_of_people: formData.number_of_people,
        pick_up_address: formData.pick_up_address,
        date: formData.date,
        depart_time: formData.depart_time,
        message: formData.message,
      },
      'PDJmBwyDJBj0tiM_U' 
    )
    .then((response) => {
          console.log('Email sent successfully:', response);
    
      setStatusMessage('Message sent successfully!');
      // Clear the message after 3 seconds
        setTimeout(() => {
            setStatusMessage('');
        }, 3000);
    })
    .catch((error) => {
      console.error('Error sending email:', error);
      // Handle errors or provide user feedback
    });
  };

  return (
    <Form onSubmit={bookingEmailHandler}>
      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <input type="text" placeholder="First Name" name="first_name" required/>
      </FormGroup>
      <FormGroup className="booking__form d-inline-block ms-1 mb-4">
        <input type="text" placeholder="Last Name"  name="last_name" required/>
      </FormGroup>

      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <input type="email" placeholder="Email" name="email" required/>
      </FormGroup>
      <FormGroup className="booking__form d-inline-block ms-1 mb-4">
        <input type="number" placeholder="Phone Number" name="phone_number" required />
      </FormGroup>
      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <input type="text" value={name} placeholder="Destination"  name="destination" required/>
      </FormGroup>
      <FormGroup className="booking__form d-inline-block ms-1 mb-4">
        <input type="text" value={price} placeholder="Price" name="price" required/>
      </FormGroup>

      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <select name="number_of_people" id="" required>
          <option value="1 person">1 Person</option>
          <option value="2 person">2 People</option>
          <option value="3 person">3 People</option>
          <option value="4 person">4 People</option>
          <option value="5+ person">5+ People</option>
        </select>
      </FormGroup>
 
      <FormGroup className="booking__form d-inline-block ms-1 mb-4">
      <input type="text" placeholder="Pick up  Address" name="pick_up_address" required/>
      </FormGroup>
      

      <FormGroup className="booking__form d-inline-block me-4 mb-4">
        <input type="date" placeholder="Journey Date" name="date" required/>
      </FormGroup>
      <FormGroup className="booking__form d-inline-block ms-1 mb-4">
        <input
          type="time"
          placeholder="Depart Time"
          className="time__picker"
          name="depart_time"
          required
        />
      </FormGroup>

      <FormGroup>
        <textarea
          rows={5}
          type="textarea"
          className="textarea"
          placeholder="Write"
          name="message"
          required
        ></textarea>
      </FormGroup>
      {statusMessage && <div>{statusMessage}</div>}
      <div className="d-flex align-items-center gap-3">
        <button className="book__now" type="submit">
                  Send Message
                </button>
      </div>
    </Form>
  );
};

export default BookingForm;

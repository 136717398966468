import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import CancelationSection from "../components/UI/CancelationSection";

import "../styles/about.css";

const About = () => {
  return (
    <Helmet title="About">
      <CommonSection title="Cancelation Policy" />
      <CancelationSection aboutClass="aboutPage" />
    </Helmet>
  );
};

export default About;

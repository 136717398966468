import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-section.css";
import aboutImg from "../../assets/all-images/about-img.jpg";

const AboutSection = ({ aboutClass }) => {
  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage"
          ? { marginTop: "0px" }
          : { marginTop: "280px" }
      }
    >
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__section-content">
              <h4 className="section__subtitle">About Us</h4>
              <h2 className="section__title">Welcome to HYM Cape Tours</h2>
              <p className="section__description">
              Welcome to HYM Cape Tours, your gateway to unparalleled exploration and discovery in the enchanting landscapes of Cape Town, South Africa. As a premier tourism company, we take pride in crafting unforgettable experiences that seamlessly blend adventure, culture, and natural beauty.

At HYM Cape Tours, we believe in transforming your travel dreams into reality, offering a diverse range of meticulously curated tours that showcase the rich tapestry of Cape Town's wonders. Whether you are a history enthusiast, nature lover, or thrill-seeker, our array of bespoke packages caters to every discerning traveler.
              </p>

              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> 
                  Airport pick-up and drop offs Services
                </p>

                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> 
                  Flexible Booking and Free Cancellations
                </p>
              </div>

              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> 
                  Exclusive Travel Insurance Packages
                </p>

                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> 
                  Local SIM Cards and Wi-Fi Services
                </p>
              </div>
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="about__img">
              <img src={aboutImg} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;

import React from "react";
import { Col } from "reactstrap";
import "../../styles/destination-item.css";
import { Link } from "react-router-dom";
import destinationsData from "../../assets/data/destinationData";

const DestinationList = () => {
  return (
    <>
      {destinationsData.map((item) => (
        <DestinationItem item={item} key={item.id} />
      ))}
    </>
  );
};

const DestinationItem = ({ item }) => {
  const { imgUrl, name, description, price } = item;

  return (
    <Col lg="4" md="6" sm="6" className="mb-5">
      <div className="blog__item">
        <img src={imgUrl} alt="" className="w-100" />
        <div className="blog__info p-3">
          <Link to={`/destination/${name}`} className="blog__title">
          <i class="ri-map-pin-line"></i> {name}
          </Link>
          <p className="section__description mt-3">
            {description.length > 100
              ? description.substr(0, 100)
              : description}
          </p>

          <Link to={`/destination/${name}`} className="read__more">
            Read More
          </Link>

          <div className="blog__time pt-3 mt-3 d-flex align-items-center justify-content-between">
            <span className="destination__pickup">
            <i class="ri-roadster-line">  </i> <p style={{marginLeft:4}}> Pickup offered</p>
            </span>

            <span className="destination__cancelation">
            <i class="ri-checkbox-circle-line"></i> <p style={{marginLeft:4}}> Free Cancelation</p>
              
            </span>
          </div>
          <div className="blog__time pt-3 mt-3 d-flex align-items-center justify-content-between">
            <span className="destination__price">
              <i class="ri-price-line"></i> {price}
              
            </span>

            <div className="d-flex align-items-center gap-3">
            <Link to={`/booking/${name}`} className="book__now">
            Book Now
          </Link>

            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};


export default DestinationList;

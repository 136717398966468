import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, FormGroup, Input } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import emailjs from 'emailjs-com';
import "../styles/contact.css";

const socialLinks = [
  {
    url: "https://www.instagram.com/hym_cape_tours/",
    icon: "ri-instagram-line",
  },
  {
    url: "#",
    icon: "ri-facebook-line",
  },
  {
    url: "#",
    icon: "ri-youtube-line",
  },
];

const Contact = () => {
      const [statusMessage, setStatusMessage] = useState('');

  const sendContactEmail = (e) => {
    e.preventDefault();

    const formData = {
      name: e.target.elements.name.value,
      email: e.target.elements.email.value,
      phone: e.target.elements.phone.value,
      message: e.target.elements.message.value,
    };

    emailjs.send(
      'service_lsg9iqw',
      'template_sqfqwhi',
      {
        to_name: 'Yves',
        from_name: formData.name,
        phone_number: formData.phone,
        user_email: formData.email,
        message: formData.message,
      },
      'PDJmBwyDJBj0tiM_U'
    )
    .then((response) => {
      console.log('Email sent successfully:', response);
      
      setStatusMessage('Message sent successfully!');
      // Add any additional actions after successful email submission
      // Clear the message after 3 seconds
        setTimeout(() => {
            setStatusMessage('');
        }, 3000);
    })
    .catch((error) => {
      console.error('Error sending email:', error);
      // Handle errors or provide user feedback
    });
  };


  return (
    <Helmet title="Contact">
      <CommonSection title="Contact" />
      <section>
        <Container>
          <Row>
            <Col lg="7" md="7">
              <h6 className="fw-bold mb-4">Get In Touch</h6>

              <Form onSubmit={sendContactEmail}>
                <FormGroup className="contact__form">
                  <Input placeholder="Your Name" type="text" name="name" required/>
                </FormGroup>
                <FormGroup className="contact__form">
                  <Input placeholder="Email" type="email" name="email" required/>
                </FormGroup>
                <FormGroup className="contact__form">
                  <Input placeholder="Phone Number" type="text" name="phone" required/>
                </FormGroup>
                <FormGroup className="contact__form">
                  <textarea
                    rows="5"
                    placeholder="Message"
                    className="textarea"
                    name="message"
                    required
                  ></textarea>
                </FormGroup>
                {statusMessage && <div>{statusMessage}</div>}
                <button className=" contact__btn" type="submit">
                  Send Message 
                </button>
              </Form>
            </Col>

            <Col lg="5" md="5">
              <div className="contact__info">
                <h6 className="fw-bold">Contact Information</h6>
                <p className="section__description mb-0">
                2A Wemyss St, Brooklyn, Cape Town, 7405, South Africa
                </p>
                <div className=" d-flex align-items-center gap-2">
                  <h6 className="fs-6 mb-0">Phone:</h6>
                  <p className="section__description mb-0">+27 79 968 3317</p>
                </div>

                <div className=" d-flex align-items-center gap-2">
                  <h6 className="mb-0 fs-6">Email:</h6>
                  <p className="section__description mb-0"> hymcapetours@gmail.com</p>
                </div>

                <h6 className="fw-bold mt-4">Follow Us</h6>

                <div className=" d-flex align-items-center gap-4 mt-3">
                  {socialLinks.map((item, index) => (
                    <Link
                      to={item.url}
                      key={index}
                      className="social__link-icon"
                      target="_blank"
                    >
                      <i className={item.icon}></i>
                    </Link>
                  ))}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};



export default Contact;

import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/CancelationPolicy.css";
import aboutImg from "../../assets/all-images/cancellation-img.jpg";

const CancelationSection = ({ aboutClass }) => {
  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage"
          ? { marginTop: "0px" }
          : { marginTop: "280px" }
      }
    >
      <Container>
        <Row>
          <Col lg="6" md="6">
            <div className="about__section-content">
              <h4 className="section__subtitle">Read more About our</h4>
              <h2 className="section__title">Cancellation Policy</h2>
              <p className="section__description">
              When booking with us, rest assured that we offer various cancellation policies tailored to different tours and activities to suit your needs. Be sure to check the applicable policy for your chosen experience before confirming your booking, which you can find in the Cancellation Policy section of each experience. For your convenience, if you have an existing booking, you can verify the applicable cancellation policy by checking the Cancellation Policy section of your ticket. Here's a breakdown of our cancellation policies:
              </p>

              <div className="about__section-items d-flex align-items-center">
                <p className="section__description  gap-2">
                  <i class="ri-checkbox-circle-line"></i> 
                 <b>Free Cancellation :</b> Cancel for a full refund if done at least 24 hours before the start time of the experience. No refunds for cancellations made less than 24 hours prior to the start time.
                </p>

                <p className="section__description  gap-2">
                  <i class="ri-checkbox-circle-line"></i> 
                  <b>Non-refundable :</b>These experiences cannot be changed or refunded for any reason.
Moderate: Full refund available if canceled at least 4 full days before the start time; no refund for cancellations less than 3 full days prior to the start time.
                </p>
              </div>

              <div className="about__section-items d-flex align-items-center">
                <p className="section__description  gap-2">
                  <i class="ri-checkbox-circle-line"></i> 
                  <b>Strict :</b> Full refund possible if canceled at least 7 full days before the start time; 50% refund if canceled 3-6 full days before the start time; no refund for cancellations less than 2 full days prior to the start time.
                </p>

                <p className="section__description  gap-2">
                  <i class="ri-checkbox-circle-line"></i> 
                  <b>Custom :</b> Specific terms and conditions for experiences with custom cancellation policies can be found in the Cancellation Policy section of the experience page.
                </p>

              </div>
               <p className="section__description  gap-2">
                  
                  Please note that cut-off times are based on the local time of the experience.
                </p>
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="about__img">
              <img src={aboutImg} alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CancelationSection;

import React from "react";

import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
import driveImg from "../assets/all-images/drive.jpg";
import ServicesList from "../components/UI/ServicesList";
import "../styles/services-list.css";

const Services = () => {
  return (
    <Helmet title="Services">
      <CommonSection title="Services" />
   
      <section>
        <Container>
          <Row>
            <Col lg="12" className="mb-5 text-center">
              <h6 className="section__subtitle">See our</h6>
              <h2 className="section__title">Popular Services</h2>
            </Col>
            <ServicesList/>
          </Row>
        </Container>
      </section>
      <section className="about__page-section">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="12">
              <div className="about__page-img">
                <img src={driveImg} alt="" className="w-100 rounded-3" />
              </div>
            </Col>

            <Col lg="6" md="6" sm="12">
              <div className="about__page-content">
                <h2 className="section__title">
                  We Are Committed To Provide Safe Ride Solutions
                </h2>
                <p className="section__description">
                  At Hym Cape Tours, your safety is our top priority, 
ensuring that every moment of your journey is not only enjoyable but also secure. 
Join us for a seamless and trustworthy transportation experience, allowing you
 to focus on savoring the beauty and excitement of your travel destination without 
 any concerns.
                </p>

                <p className="section__description">
                For the adventurous at heart, HYM Cape Tours offers thrilling 
                experiences such as shark cage diving off the coast of Robben Island. 
                Delve into the depths of the ocean,
                 witnessing the captivating marine life in its natural habitat, all with a
                  commitment to ensuring your safety.
                 
                </p>
                <p className="section__description"> With customizable itineraries and a team of dedicated guides, 
                  your journey with HYM Cape Tours transforms every moment into a 
                  personalized exploration, where the magic of Cape Town unfolds 
                  in a tapestry of culture, history, and natural beauty. </p>

                <div className=" d-flex align-items-center gap-3 mt-4">
                  <span className="fs-4">
                    <i class="ri-phone-line"></i>
                  </span>

                  <div>
                    <h6 className="section__subtitle">Need Any Help?</h6>
                    <h4>+27 79 968 3317</h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Services;

import React from "react";
import { Routes, Route} from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import BookingDetails from "../pages/BookingDetails";
import Destination from "../pages/Destination";
import DestinationDetails from "../pages/DestinationDetails";
import NotFound from "../pages/NotFound";
import Contact from "../pages/Contact";
import Services from "../pages/Services";
// import CombinedTours from "../pages/CombinedTours";
import CancelationPolicy from "../pages/CancelationPolicy"


const Routers = () => {
  return (
    <Routes>

      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/services" element={<Services/>} />
      <Route path="/booking/:slug" element={<BookingDetails />} />
      <Route path="/destinations" element={<Destination />} />
      <Route path="/destination/:slug" element={<DestinationDetails />} />
      <Route path="/contact" element={<Contact />} />
      {/* <Route path="/combined-tours" element={<CombinedTours />} /> */}
      <Route path="/cancelation" element={<CancelationPolicy />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routers;
const serviceData = [
  {
    id: 1,
    title: "Combined Destinations packages",
    icon: "ri-map-pin-2-line",
    desc: "Combine multiple destinations into one package for a comprehensive travel experience.",
  },
  {
    id: 2,
    title: "Whole City Tour",
    icon: "ri-community-line",
    desc: "Explore the entire city with our guided city tour.",
  },
  {
    id: 3,
    title: "Free Cancellation",
    icon: "ri-roadster-line",
    desc: "Enjoy peace of mind with our free cancellation policy.",
  },
  {
    id: 4,
    title: "Fast & Easy Booking",
    icon: "ri-timer-flash-line",
    desc: "Book your trip quickly and easily with our user-friendly booking system.",
  },
  {
    id: 5,
    title: "Pickup and Drop Off",
    icon: "ri-map-pin-line",
    desc: "Convenient pickup and drop-off services from your desired locations.",
  },
  {
    id: 6,
    title: "Airport Pick Up",
    icon: "ri-flight-takeoff-line",
    desc: "Reliable airport pickup service to start your journey hassle-free.",
  },
];



export default serviceData;
